new Swiper("#Swiper", {
	spaceBetween: 30,
	autoplay: {
		delay: 7000,
		disableOnInteraction: true,
	},
	pagination: {
		el: ' .swiper-pagination',
		clickable: true,
	},
});
